import React from "react";

function Undefined() {

    return (
        <div className="page">

            <h1 className="centered_headers">Page Not Found.</h1>

            <img src="/images/website/powersIndustriesLogo.png" alt="logo" className="projects-icons" />

        </div>
    );
}

export default Undefined; 