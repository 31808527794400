import React from "react";
import { Link } from "react-router-dom";

function ProjectCard(props: any) {

    return (

        <div className="projects_card">

            <Link
                to={props.url}
                target="_blank" >

                {props.title}

            </Link>

            <p>{props.description}</p>

        </div>

    );
}

export default ProjectCard;