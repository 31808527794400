import React, {useEffect, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import Work from "./View/Work";
import Footer from "./View/Footer";
import Header from "./View/Header";
import Home from "./View/Home";
import Undefined from "./View/Undefined";
import Articles from "./View/Articles";
import NavigationTabType from "./Model/NavigationTabType";
import GitRepo from "./Model/GitRepo";
import Article from "./Model/Article";
import ArticlePage from "./View/ArticlePage";

function App() {
    const navigate = useNavigate();
    const [githubRepos, setGithubRepos] = useState<GitRepo[]>([]);
    const [articles, setArticles] = useState<Article[]>([]);

    useEffect(() => {
        const fetchGithubData = async () => {
            try {
                const response = await fetch(
                    'http://api.lukaspowers.com/v1/git_projects', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await response.json();
                let repos: GitRepo[] = [];
                result.data.forEach((repo: GitRepo) => {
                    repos.push(repo);
                });

                repos.sort((a, b) =>
                    new Date(b.pushed_at).getTime() - new Date(a.pushed_at).getTime());

                setGithubRepos(repos);
            } catch (err) {
                console.log(err);
            }
        };

        const fetchArticleData = async () => {
            try {
                const response = await fetch(
                    'http://api.lukaspowers.com/v1/all_articles', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await response.json();
                let articles: Article[] = [];
                result.data.forEach((repo: Article) => {
                    articles.push(repo);
                });

                articles.sort((a, b) => b.date - a.date);

                setArticles(articles);
            } catch (err) {
                console.log(err);
            }
        };

        if (githubRepos.length === 0) {
            fetchGithubData();
        }

        if (articles.length === 0) {
            fetchArticleData();
        }

    }, [githubRepos]);

    function changedTab(navigationTab: NavigationTabType) {
        switch (navigationTab) {
            case NavigationTabType.home : {
                navigate("/");
                break;
            }
            case NavigationTabType.work : {
                navigate("/work");
                break;
            }
            case NavigationTabType.articles : {
                navigate("/articles");
                break;
            }
            default : {
                navigate("/");
                break;
            }
        }
    }

    return (
        <div>

            <Header onTabChanged={changedTab} />

            <Routes>

                <Route path="/" element={<Home />} />
                <Route path="/work" element={<Work inRepos={githubRepos} />} />
                <Route path="/articles" element={<Articles inArticles={articles} />} />
                <Route path="/articles/:id" element={<ArticlePage inArticles={articles} />} />
                <Route path="*" element={<Undefined />} />

            </Routes>

            <Footer />


        </div>
    );
}

export default App;
