import React from "react";
import NavigationTabType from "../Model/NavigationTabType";

function Header(props: any) {

    function handleClick(navigationTab: NavigationTabType): void {
        props.onTabChanged(navigationTab);
    }

    return (
        <header>
            <button
                id="home"
                onClick={ () => handleClick(NavigationTabType.home) }>
                Home
            </button>
            <button
                id="work"
                onClick={ () => handleClick(NavigationTabType.work) }>
                Work
            </button>
            <button
                id="articles"
                onClick={ () => handleClick(NavigationTabType.articles) }>
                Articles
            </button>
        </header>
    );
}

export default Header;