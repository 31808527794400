import React from "react";
import { Link } from "react-router-dom";
import GitRepo from "../../Model/GitRepo";

function GitRepoCard({ inRepo }: { inRepo: GitRepo }) {

    function getHumanReadableDate() {
        const currDate = new Date(inRepo.pushed_at);
        return currDate.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
    }

    return (

        <div className="projects_card">

            <Link
                to={inRepo.html_url}
                target="_blank" >

                {inRepo.name}

            </Link>

            <p>Latest Push: {getHumanReadableDate()}</p>
            <p>Language: {inRepo.language}</p>
            <p>{inRepo.description}</p>

        </div>

    );
}

export default GitRepoCard;