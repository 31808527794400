import React from "react";
import Article from "../../Model/Article";

function ArticleCard(inArticles: Article) {

    function unixTimeToDate(unixTime: number): string {
        let date = new Date(unixTime * 1000);
        return date.toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' });
    }

    return (

        <div className="article_card">

            <h2>
                {inArticles.title}
            </h2>

            <p className="article_card_date">
                {unixTimeToDate(inArticles.date)}
            </p>

        </div>

    );
}

export default ArticleCard;