import React from "react";
import ProjectCard from "./Components/ProjectCard";
import GitRepoCard from "./Components/GitRepoCard";
import Websites from "../Data/Websites.json";
import GitRepo from "../Model/GitRepo";

function Work({inRepos}: {inRepos: GitRepo[]}) {

    function getGitRepos() {
        if (inRepos.length === 0) {
            return (
                <div>
                    No Github repository found. Come back again later!
                </div>
            );
        }

        return (
            <div>
                {
                    inRepos.slice(0, 5).map(repo => (
                        <GitRepoCard
                            key={repo.name}
                            inRepo={repo} />
                    ))
                }
                <p>And many more...</p>
            </div>
        )
    }

    return (
        <div className="page_content">

            {/* Web */}
            <h1 className="centered_headers">
                WEBSITES
            </h1>
            <div>
                {/* lukaspowers.com website */}
                <p>I made the website you're currently on. <a href="https://lukaspowers.substack.com/p/a-video-game-developer-makes-a-website" target="_blank">Here</a> is the link to an article where I talk about the development process.</p>
                {
                    Websites && Websites.map(website => (
                        <ProjectCard
                            key={website.title}
                            title={website.title}
                            description={website.description}
                            url={website.url}
                        />
                    ))
                }

            </div>

            {/* Github */}
            <h1 className="centered_headers">
                LATEST GITHUB REPOS
            </h1>
            {getGitRepos()}

            {/* Powers Industries */}
            <h1 className="centered_headers">
                POWERS INDUSTRIES, LLC
            </h1>

            <div className="projects_PI">
                <img src="/images/website/powersIndustriesLogo.png" alt="logo" className="projects_PIicons" />
            </div>
            <p>
                Powers Industries, LLC is a technology company that specializes in developing performant full-stack tools and applications.
            </p>

        </div>
    );
}

export default Work;
