import React from "react";
import {useNavigate} from "react-router-dom";
import Article from "../Model/Article";
import ArticleCard from "./Components/ArticleCard";

function Articles({inArticles}: {inArticles: Article[]}) {
    const navigate = useNavigate();

    function onArticleClicked(id: string) {
        navigate(`/articles/${id}`);
    }

    if (inArticles.length === 0) {
        return (
            <div>
                <p>No articles right now. Come back again sometime soon!</p>
            </div>
        );
    }

    return (
       <div className="page_content">
            <h1 className="centered_headers">
                ARTICLES
            </h1>
            {
                inArticles.map((article: Article) => (
                    <div onClick={() => { onArticleClicked(article.id) }}
                         key={article.id}>
                        <ArticleCard
                            id={article.id}
                            key={article.id}
                            title={article.title}
                            date={article.date}
                        />
                    </div>
                ))
            }
        </div>
    );
}

export default Articles;