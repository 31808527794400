import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import {marked} from "marked";
import Article from "../Model/Article";
import Undefined from "./Undefined";

function ArticlePage(props: any) {
    const { id } = useParams<{ id: string }>();
    const [articleData, setArticleData] = useState<Article>({
        id: "",
        title: "",
        date: 0,
    });
    const [article, setArticle] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    'http://api.lukaspowers.com/v1/articles/' + id, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await response.json();
                const htmlString = marked(result.data);

                setArticle(htmlString.toString());

                for(const art of props.inArticles as Article[]) {
                    if (art.id === id) {
                        setArticleData(art);
                        break;
                    }
                }
            } catch (err) {
                console.log(err);
            }
                
            setLoading(false);
        };

        if (article === "") {
            fetchData();
        }

    }, [article, id, props.inArticles]);

    function unixTimeToDate(unixTime: number): string {
        let date = new Date(unixTime * 1000);
        return date.toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' });
    }

    // Loading State.
    if (loading) {
        return (
            <div className="page_content">
                <p>Loading article...</p>
            </div>
        );
    }

    // Failed to find article.
    if (article === "" || article === null || articleData === null) {
        return (
            <Undefined />
        );
    }

    // Display article.
    return (
        <div className="page_content">
            <div>
                <h1>{articleData.title}</h1>
                <p>{unixTimeToDate(articleData.date)}</p>
            </div>
            <div className="markdown_body" dangerouslySetInnerHTML={{ __html: article }} />
        </div>
    );
}

export default ArticlePage;
