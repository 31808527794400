import React from "react";
import { Link } from "react-router-dom";
import socials from "../Data/Socials.json";

function Home() {

    return (
        <div className="page_content">

            <div className="centered_content">
                <img src="/images/website/profilePic.jpg" alt="profile" className="profile" />
            </div>

            <p>
                My name is Luke and I am a software engineer with a passion for solving complex problems.
                I have worked in a variety of industries including academic research, a technology startup, an exercise equipment manufacturer, the video game industry, and the medical industry.
            </p>
            <p>
                I love creating applications both large and small that help advance the world of software development.
                The majority of my projects are written in C/C++ or Java and cover a range of environments like full-stack web applications, command line tools, and even video games.
            </p>
            <p>
                Feel free to check out my <a href="work">Work</a> and give my <a href="articles">Articles</a> a read.
            </p>

            <div className="home-socials">
                <p>Looking to get in touch?</p>

                <div className="home_socials">
                    <Link
                        to={socials.github}
                        target="_blank" >
                        GitHub
                    </Link>
                    <Link
                        className="home_link_right"
                        to={socials.linkedin}
                        target="_blank" >
                        LinkedIn
                    </Link>
                </div>

            </div>

        </div>
    );
}

export default Home;
